import _defineProperty from "/vercel/path1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import axiosConnect from "./axiosConnect";
var X_CSRF = "x-csrftoken";

// simple wrapper around axios connect for simple fetch requests
// you will need to handle the errors from catch
function getService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var paramsSerializer = arguments.length > 2 ? arguments[2] : undefined;
  var timeout = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 20000;
  var options = arguments.length > 4 ? arguments[4] : undefined;
  return axiosConnect.get(url, _objectSpread({
    params: params,
    paramsSerializer: paramsSerializer,
    timeout: timeout
  }, options)).then(function (response) {
    return response.data;
  });
}
function postService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return axiosConnect.post(url, params, config).then(function (_ref) {
    var data = _ref.data;
    return data;
  });
}
function patchService(url, body) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return axiosConnect.patch(url, body, {
    params: params
  }).then(function (_ref2) {
    var data = _ref2.data;
    return data;
  });
}
function putService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return axiosConnect.put(url, params).then(function (_ref3) {
    var data = _ref3.data;
    return data;
  });
}
function deleteService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return axiosConnect["delete"](url, params).then(function (_ref4) {
    var data = _ref4.data;
    return data;
  });
}
export default {
  getService: getService,
  postService: postService,
  patchService: patchService,
  putService: putService,
  deleteService: deleteService,
  X_CSRF: X_CSRF
};