import { useRouter } from "next/router";
import constants from "../utils/constants";
function useCheckLCContainer() {
  var _useRouter = useRouter(),
    pathname = _useRouter.pathname;
  if (pathname.includes(constants.SCREENS.LUNGCANCER)) {
    return true;
  }
  return false;
}
export default useCheckLCContainer;