import classNames from "classnames";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

import AwesomeIcon from "../AwesomeIcon";

function Alert({ className, onClick, dynamicMessagePart, messageKey, showProgress }) {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={classNames(
          "bg-red-600 py-4 px-5 hidden sm:flex space-x-3 items-center text-base text-gray-100",
          { [className]: className }
        )}
        onClick={onClick}
      >
        <AwesomeIcon name={faTriangleExclamation} />
        <p>{t(messageKey, dynamicMessagePart)}</p>
      </div>
      {showProgress && (
        <div className=" bg-yellow-400 h-2 relative">
          <div className="w-[30%] bg-gradient-to-r h-2 absolute animate-moveBar from-transparent to-gray-800"></div>
        </div>
      )}
    </>
  );
}

export default Alert;
