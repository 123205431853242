import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
import { getIsOnpremise } from "../../../utils";

const useLogoutService = (isLcWorklist) => {
  const isOnPremise = getIsOnpremise() || isLcWorklist;
  return useMutation(() =>
    servicesUtils
      .getService(isOnPremise ? "/accounts/local/logout/" : "/accounts/logout/", {
        next: window.location.href,
      })
      .then(() => {
        window.api?.logoutUser();
      })
  );
};

export default useLogoutService;
